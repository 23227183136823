<script setup lang="ts">
import moment from 'moment'
import {usePage} from "@inertiajs/inertia-vue3";

const copyRight = moment().year() + " &copy; Beeldbank ";

const props = defineProps({
	bg: String
});
const page = usePage();

</script>
<template>
	<div class="relative min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-white">
		<img class="absolute pointer-events-none w-full h-full object-cover "
		     :src="page.props.value.company?.login_bg?.original_url ?? '/bg.jpeg'" alt="bg">
		<span class="absolute pointer-events-none w-full h-full opacity-60 bg-primaryBackground blur-0"></span>
		<div class="relative mb-8">
			<slot name="logo"/>
		</div>
		<div
				class="relative w-full sm:max-w-sm px-6 py-4 shadow-md bg-backgroundSemiTransparent overflow-hidden sm:rounded">
			<slot/>
		</div>
		<span class="relative mt-8 w-full sm:max-w-sm text-right text-xs text-primaryText md:mt-0 md:order-1"
		      v-html="copyRight"></span>
	</div>
</template>
